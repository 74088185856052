<template>
	<store-page
		:page="page"
		:store-obj="store"
		:user="user"
		:second-product="secondBuiltInProduct"
		:resell-rights-uuid="resellRightsUuid"
		:redirect-username="affiliateUsername"
		:showBackButton="false"
	/>
</template>

<script>
	import StorePage from '~/components/StorePage.vue'
	export default {
		name: 'ResellRightsStorePage',
		components: {
			StorePage,
		},
		head() {
			const title = '@' + this.user.username
			const description = this.page.data.product.title
			const image = this.$optimizeImage(this.page.data.product.image, { width: 500 })
			return {
				title,
				meta: [
					{ hid: 'description', name: 'description', content: description },
					{ hid: 'og:title', property: 'og:title', content: title },
					{ hid: 'og:description', property: 'og:description', content: description },
					{ hid: 'og:image', property: 'og:image', content: image },
					{ hid: 'twitter:title', name: 'twitter:title', content: title },
					{ hid: 'twitter:description', name: 'twitter:description', content: description },
					{ hid: 'twitter:image', name: 'twitter:image', content: image },
				],
				link: [
					{ rel: 'icon', type: 'image/x-icon', href: this.$optimizeImage(this.user.user_image, { width: 16, height: 16, format: 'ico' }) },
				],
			}
		},
		async asyncData({ params, $axios, store, redirect, req, app }) {
			const uuid = params.uuid

			// use the uuid to get required info for identifying the affiliate product
			async function getResellRightsInfo(uuid) {
				if (!uuid || uuid.length > 36) return null
				try {
					var params = {
						uuid: uuid,
					}
					return await $axios.$get('v1/resell-rights/get-link-info', {
						baseURL: process.env.NUXT_ENV_PYTHON_API,
						params: params,
					})
				} catch (err) {
					console.error('error get resell rights info: ', err)
					redirect(`/unavailable`)
					return null
				}
			}
			const [resell_rights_info] = await Promise.all([getResellRightsInfo(params.uuid)])
			const username = resell_rights_info.username
			const slug = resell_rights_info.page_slug
			const resellRightsUuid = params.uuid
			const isMRR = resell_rights_info.is_mrr

			async function getStorePageBySlug(username, slug) {
				try {
					return await $axios.$get(`v1/pages/${slug}`, {
						baseURL: process.env.NUXT_ENV_PYTHON_API,
						params: { username: username, resell_rights_uuid: resellRightsUuid },
					})
				} catch (err) {
					console.error('error get page data: ', err)
					return null
				}
			}

			async function getStorePageById(username, page, slug) {
				try {
					return await $axios.$get(`v1/stores/${slug}/page/${page}`, {
						baseURL: process.env.NUXT_ENV_PYTHON_API,
						params: { username: username, slug: slug },
					})
				} catch (err) {
					console.error('error get page data: ', err)
					return null
				}
			}

			const pageResp = await getStorePageBySlug(username, slug)

			if (pageResp === null) {
				redirect(`/${username}`)
				return {}
			}

			if (pageResp === 'subscription_inactive') {
				redirect('/unavailable')
				return {}
			}

			if (pageResp.user && pageResp.user.color_code) {
				store.commit('setUserPrimaryColor', pageResp.user.color_code)
			}

			if (!pageResp.store || !pageResp.page || pageResp.page?.type === 'link') {
				redirect(`/${username}`)
				return {}
			}
			const page = pageResp.page

			let secondBuiltInProduct = undefined
			if (page?.data?.second_built_in_product?.enabled) {
				const secondPageResp = await getStorePageById(username, page.data.second_built_in_product.page_id, pageResp.store.slug)
				if (secondPageResp && !secondPageResp.page.data.product.price.interval) {
					secondBuiltInProduct = secondPageResp
				}
			}

			const user = pageResp.user
			if (typeof user.data.socials === 'string') {
				user.data.socials = JSON.parse(user.data.socials)
			}

			store.commit('setUser', user)
			return {
				store: pageResp.store,
				page,
				user,
				secondBuiltInProduct: secondBuiltInProduct?.page,
				resellRightsUuid,
				affiliateUsername: resell_rights_info.affiliate_username,
			}
		},
	}
</script>
