import { TAG_TYPES_LIST } from '../constants'
import { isEmpty } from '~/stan-vue-shared/components/utils'

export default {
	computed: {
		userPrimaryColor() {
			return (
				(this.page?.data && this.page?.data.primary_color) ||
				(this.store.data && this.store.data.primary_color) ||
				this.$store.getters.getUserPrimaryColor
			)
		},
		userSecondaryColor() {
			return (
				(this.page?.data && this.page?.data.secondary_color) ||
				(this.store.data && this.store.data.secondary_color) ||
				this.$store.getters.getUserSecondaryColor
			)
		},
		userPrimaryFont() {
			return (
				(this.page?.data && this.page?.data.primary_font) ||
				(this.store.data && this.store.data.primary_font) ||
				this.$store.getters.getUserPrimaryFont
			)
		},
		userBackground() {
			return { background: this.userPrimaryColor }
		},
		product() {
			return this.page.data.product
		},
		storeTheme() {
			if (this.$route.query?.theme) {
				return this.$route.query.theme
			}
			return this.store.data.theme || 'default'
		},
		deviceStyle() {
			return this.$mobile.isIosDevice() ? { 'padding-bottom': '55px' } : {}
		},
	},
	data() {
		return {
			isLoaded: false,
			formSubmitting: false,
			trackingProps: {},
			tags: {},
		}
	},
	methods: {
		setupTracking() {
			const utmMedium = this.$route.query.utm_medium ?? this.$cookies.get('utm_medium')
			const utmSource = this.$route.query.utm_source ?? this.$cookies.get('utm_source')
			const utmCampaign = this.$route.query.utm_campaign ?? this.$cookies.get('utm_campaign')
			const currTags = this.$cookies.get('tags') || {}
			this.tags = currTags

			if (utmMedium) {
				this.$cookies.set('utm_medium', utmMedium)
				this.trackingProps['utmMedium'] = utmMedium
			}
			if (utmSource) {
				this.$cookies.set('utm_source', utmSource)
				this.trackingProps['utmSource'] = utmSource
			}
			if (utmCampaign) {
				this.$cookies.set('utm_campaign', utmSource)
				this.trackingProps['utmCampaign'] = utmCampaign
			}

			// check if query params have tags we want to track, and add them to current tracked tags on user
			for (const tag of TAG_TYPES_LIST) {
				const tagQuery = this.$route.query[tag]
				if (tagQuery) {
					currTags[tag] = tagQuery
					this.tags[tag] = tagQuery
				}
			}
			!isEmpty(currTags) && this.$cookies.set('tags', currTags)
		},
	},
	beforeMount() {
		this.setupTracking()
	},
}
